<template>
  <section>
    <b-row fluid>
      <b-col xl="8" lg="8" sm="12" xs="12" cols="12">
        <b-card-actions
            class="cari-liste-card"
            ref="cari-kartlar"
            @refresh="refreshStop('cari-kartlar')"
        >
          <b-row class="mt-50">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">{{
                    id == 0 ? 'Gider Listesi' : simpleGroupGet() + ' Grubuna Ait Cari Kartlar'
                  }}</h4>
                <b-button
                    variant="flat-primary"
                    class="text-white float-right bg-info bg-lighten-1 mr-50"
                    v-if="id != 0"
                    @click="showAll"
                    :style="{backgroundColor:$store.getters.COLORS_GET.cardTitleButton + '!important'}"
                >
                  Tüm Giderleri Göster
                </b-button>
              </b-col>
            </div>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap mt-4">
            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
                  class="unPrint"
              >
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="perPageChange($event)"
                    size="sm"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <b-input-group size="sm" class="search-bar">
              <b-input-group-prepend :style="{height : width < 768 ? '30px!important':''}">
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="search-input mb-0"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="filter" type="search"/>
            </b-input-group>
          </div>
          <hr/>
          <b-table
              striped
              hover
              class="position-relative mt-1 gider-listesi"
              :per-page="perPage"
              :items="listGet"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >
            <template #cell(giderAdi)="data">
              <strong v-if="data.item.giderAdi">{{ data.item.giderAdi }}</strong>
            </template>
            <template #cell(giderGrupID)="data">
              <feather-icon icon="UsersIcon"/>
              <strong class="ml-50">
                {{ $store.getters.GIDER_GRUP_NAME_GET(data.item.giderGrupID) }}
              </strong>
            </template>
            <template #cell(vergiNumarasi)="data">
              <font-awesome-icon icon="fa-regular fa-address-card"/>
              <strong class="ml-50">
                {{ data.item.vergiNumarasi }}
              </strong>
            </template>
            <template #cell(kayitTarih)="data">
              <p class="p-0 m-0 d-block font-weight-bold" v-if="data.item.kayitTarih">{{
                  data.item.kayitTarih.slice(0, 16)
                }} </p>
            </template>
            <template #cell(cikisTuru)="data">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                  {{
                    data.item.cikisTuru == 1 ? 'Nakit' : data.item.cikisTuru == 2 ? 'Kredi Kartı' : data.item.cikisTuru == 3 ? 'Havale' : ''
                  }}
                </b-col>
              </b-row>
            </template>
            <template #cell(tutar)="data">
              <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                  <p class="p-0 m-0 d-block font-weight-bold"
                     v-if="data.item.tutar != 0 && data.item.tutar != 'null' && data.item.tutar != null"
                  >
                    {{
                      Number(data.item.tutar)
                    }}
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </p>
                  <p v-else>
                    0
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </p>
                  <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      @click="itemDelete(data.item.giderID)"
                      v-b-tooltip="'Gideri Sil'"
                  >
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-table>
          <hr/>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="6">
              <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
            </b-col>
            <b-col cols="6">
              <b-pagination
                  v-model="currentPage"
                  @change="currentPageChange($event)"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0 float-right"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
      <b-col xl="4" lg="4" sm="12" cols="12" xs="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-group-edit"
              ref="cari-group-edit"
              @refresh="refreshStop('yeni-gider')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">Gider Ekle</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-4">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="Açıklama"
                      label-for="hi-first-name"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-comment"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-first-name"
                          autocomplete="off"
                          v-model="addItem.aciklama"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Tutar"
                      label-for="hi-first-name"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-turkish-lira-sign"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-first-name"
                          autocomplete="off"
                          v-model="addItem.tutar"
                          :step="priceStep"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Gider Grubu"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <model-list-select
                        class=""
                        :list="grupList"
                        option-value="giderGrupID"
                        option-text="grupAdi"
                        placeholder="Hızlı Seçim"
                        v-model="addItem.grupTuru"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Ödeme Türü"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <model-list-select
                        :list="odemeTuruOptions"
                        option-value="key"
                        option-text="title"
                        placeholder="Hızlı Seçim"
                        v-model="addItem.odemeTuru"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="itemSave()"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    Kaydet
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-col>
    </b-row>
    <b-sidebar
        id="add-grup"
        :visible="newGrup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-grup-active', val)"
        ref="newCariGrup"
    >
      <template>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Gider Grubu Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="sidebarClosed"
          />
        </div>
        <b-form @submit.prevent id="new-cari-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="newGrupItem.grupAdi"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Grup Türü"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <model-list-select
                      :list="newGrupItem.grupTuruOptions"
                      v-model="newGrupItem.grupTuru"
                      option-value="text"
                      option-text="text"
                      placeholder="Hızlı Seçim"
                  >
                  </model-list-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!newGrupItem.grupAdi"
                  @click="grupSave('kayit')"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="sidebarClosed"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import axios from 'axios'
import router from '@/router'

export default {
  components: {
    BSidebar,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      addItem: {
        aciklama: '',
        tutar: '',
        grupTuru: '',
        odemeTuru: {
          title: 'Nakit',
          key: 1,
        },
      },
      odemeTuruOptions: [
        {
          title: 'Nakit',
          key: 1,
        },
        {
          title: 'Kredi Kartı',
          key: 2,
        },
        {
          title: 'Havale',
          key: 3,
        },
      ],
      editItem: {
        aciklama: '',
        tutar: 0,
        grupTuru: '',
        odemeTuru: '',
      },
      fields: [
        {
          key: 'giderAdi',
          label: 'Gider Açıklaması',
          sortable: true,
          thStyle: {
            width: '25%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },
        },
        {
          key: 'giderGrupID',
          label: 'Gider Grubu',
          sortable: true,
          thStyle: {
            width: '15%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: true,
          thStyle: {
            width: '19%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '19%' },
        },
        {
          key: 'cikisTuru',
          label: 'Ödeme Türü',
          sortable: true,
          thStyle: {
            width: '13%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '13%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thStyle: {
            width: '13%',
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '13%' },
        },
      ],
      newGrupItem: {
        grupAdi: '',
        grupTuru: '',
        grupTuruOptions: [
          {
            text: 'Personel',
          }, {
            text: 'Ofis Gideri',
          }, {
            text: 'Tüketim Gideri',
          }, {
            text: 'Diğer Gider',
          },
        ],
      },
      newGrup: false,
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      toplamKayit: 1,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    grupList() {
      let grupList = []
      grupList.push(Object.values(store.getters.GIDER_GRUP_LISTESI))
      grupList[0].push({
        grupAdi: 'Yeni Gider Grupu Oluştur',
        giderGrupID: '-1',
        yeni: true,
      })
      return Object.values(_.orderBy(grupList[0], ['yeni', 'grupTuru'], ['asc', 'desc']))
    },
    listGet() {
      return Object.values(store.getters.GIDER_LISTESI)
    },
  },
  watch: {
    addItem: {
      deep: true,
      handler(newVal) {
        if (newVal.grupTuru == -1) {
          this.newGrup = true
          this.$root.$emit('bv::toggle::collapse', 'add-grup')
        }
      },
    },
    '$route.params.id': function (newVal) {
      store.dispatch('giderListesi', {
        uzunluk: this.perPage,
        baslangic: 0,
        giderGrupID: newVal,
      })
          .then(res => {
            this.toplamKayit = Object.keys(res.liste).length
            this.totalRows = res.toplamKayit
          })
    },
    filter(newVal) {
      this.$refs['cari-kartlar'].showLoading = true
      this.$store
          .dispatch('giderListesi', {
            uzunluk: this.perPage,
            baslangic: 0,
            searchKey: newVal,
            giderGrupID: this.id,
          })
          .then(res => {
            this.$refs['cari-kartlar'].showLoading = false
            this.toplamKayit = Object.keys(res.liste).length
            this.totalRows = res.toplamKayit
          })
    },
  },
  methods: {
    grupSave(islem) {
      store.dispatch('giderGroupSave', {
        tur: this.newGrupItem.grupTuru,
        adi: this.newGrupItem.grupAdi,
        islem,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.newGrup = false
              this.addItem.grupTuru = res.data.giderGrupID
              store.dispatch('giderGrupListesi')
              this.newGrupItem.grupTuru = ''
              this.newGrupItem.grupAdi = ''
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
              this.newGrupItem.grupTuru = ''
              this.newGrupItem.grupAdi = ''
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    sidebarClosed() {
      this.addItem.grupTuru = ''
      this.newGrup = false
    },
    itemDelete(id) {
      store.dispatch('giderRemove', {
        giderID: id,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('giderListesi', {
                uzunluk: this.perPage,
                baslangic: 0,
                giderGrupID: this.id,
                searchKey: this.filter,
              })
                  .then(res => {
                    this.totalRows = res.toplamKayit
                    this.toplamKayit = Object.keys(res.liste).length
                  })
              this.itemDefault()
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    itemSave() {
      store.dispatch('giderIslem', {
        aciklama: this.addItem.aciklama,
        odemeTuru: this.addItem.odemeTuru.key,
        grupID: this.addItem.grupTuru,
        tutar: this.addItem.tutar,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('giderListesi', {
                uzunluk: this.perPage,
                baslangic: 0,
                giderGrupID: this.id,
                searchKey: this.filter,
              })
                  .then(res => {
                    this.totalRows = res.toplamKayit
                    this.toplamKayit = Object.keys(res.liste).length
                  })
              this.itemDefault()
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    showAll() {
      this.$router.push({ path: '/giderListesi/0' })
      this.id = 0
    },
    simpleGroupGet() {
      return store.getters.GIDER_GRUP_NAME_GET(this.id)
    },
    perPageChange(newVal) {
      const veri = this.currentPage * newVal - newVal
      this.$refs['cari-kartlar'].showLoading = true
      this.$store
          .dispatch('giderListesi', {
            uzunluk: newVal,
            baslangic: veri,
            searchKey: this.filter,
            giderGrupID: this.id,
          })
          .then(res => {
            this.$refs['cari-kartlar'].showLoading = false
            this.toplamKayit = Object.keys(res.liste).length
            this.totalRows = res.toplamKayit
          })
    },
    currentPageChange(newVal) {
      const veri = newVal * this.perPage - this.perPage
      this.$refs['cari-kartlar'].showLoading = true
      this.$store
          .dispatch('giderListesi', {
            uzunluk: this.perPage,
            baslangic: veri,
            searchKey: this.filter,
            giderGrupID: this.id,
          })
          .then(res => {
            this.$refs['cari-kartlar'].showLoading = false
            this.toplamKayit = Object.keys(res.liste).length
            this.totalRows = res.toplamKayit
          })
    },
    itemRemove(id) {
      store.dispatch('cariCardRemove', id)
          .then((res, position = 'bottom-right') => {
            const veri = this.currentPage * this.perPage - this.perPage
            store.dispatch('cariCardlar', {
              uzunluk: this.perPage,
              baslangic: veri || 0,
              searchKey: this.filter,
              cariGrupID: this.id,
            })
                .then(res => {
                  this.totalRows = res.toplamKayit
                })
            if (res.result.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else if (res.result.status == 303) {
              this.$swal({
                title: res.result.errMsg,
                text: res.result.helpMsg,
                icon: 'warning',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                showCloseButton: true,
                buttonsStyling: false,
              })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'cari-kartlar'].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    itemDefault() {
      this.addItem.aciklama = ''
      this.addItem.tutar = ''
      this.addItem.grupTuru = ''
      this.addItem.odemeTuru = {
        title: 'Nakit',
        key: 1,
      }
    },
  },
  created() {
    store.dispatch('giderListesi', {
      searchKey: this.filter,
      uzunluk: this.perPage,
      baslangic: 0,
      giderGrupID: this.id,
    })
        .then(res => {
          this.totalRows = res.toplamKayit
          this.toplamKayit = Object.keys(res.liste).length
        })
    store.dispatch('giderGrupListesi')
  },
  beforeDestroy() {
    store.commit({
      type: 'GIDER_LIST_ACTION',
      durum: 'remove',
    })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style>
.table th, .table td {
  padding: 0.22rem 0.1rem !important;
}

.fa-exchange-alt {
  display: none !important;
}

.msl-searchable-list__items {
  border-top: none !important;
}

.cari-liste-card .card-header, .cari-group-add .card-header, .cari-group-edit .card-header {
  display: none !important;
}

.transfer-modal .msl-multi-select__list {
  width: 43% !important;
}
</style>
<style scoped>
#new-cari-form {
  padding: 30px !important;
}

.yetkili-name {
  background-color: #3F979B !important;
}

.search-bar {
  width: 20% !important;
}

.transfer-modal ::-webkit-scrollbar {
  width: 7px !important;
}

.transfer-modal ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.item-group-tur {
  position: absolute !important;
  margin-left: 3%;
}

.list-group-item {
  border: none !important;
  cursor: pointer !important;
  transition: 200ms;
}

.list-group-item:hover, span p {
  transition: 200ms;
  color: black !important;
}

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.gider-listesi button {
  border: none !important;
}

.transfer-icon {
  background-color: #1F8A70 !important;
}

.transfer-modal .form-group {
  width: 100% !important;
  float: right !important;
}

.msl-multi-select {
  width: 108% !important;
}

#firma-unvan-yetkili {
  margin-top: 2px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .search-bar {
    width: 50% !important;
  }
}

</style>
